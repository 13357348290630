<template>
  <div class="form-content">
    <Loader v-if="loading" />
    <div style="padding: 30px">
      <v-row class="form-headline-content">
        <v-col>
          <h2>Festgeld Vergleich</h2>
        </v-col>
        <v-col>
          <div class="download-file">
            <!--            <v-card-actions class="pl-0 pr-0 flex-wrap">
              <v-tooltip :attach="true" bottom nudge-top="5" :color="baseColor">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" @click="exportPdf">
                    <v-icon color="#fff">mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span class="inter hint-color">PDF erstellen</span>
              </v-tooltip>
            </v-card-actions>
            <v-card-actions class="pl-0 pr-0 flex-wrap">
              <v-tooltip :attach="true" bottom nudge-top="5" :color="baseColor">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="secondary accent-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="printForm"
                  >
                    <v-icon color="#fff">{{ 'mdi-printer' }}</v-icon>
                  </v-btn>
                </template>
                <span class="inter hint-color">Seite drucken</span>
              </v-tooltip>
            </v-card-actions>-->
          </div>
        </v-col>
      </v-row>
      <div>
        <v-row class="flex-wrap banks-column">
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <fieldset>
              <legend>Ihre Bank oder Vergleichsbank 1</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamFestGeld.bankname1"
                    :hypotheken="false"
                    :festGeld="true"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    storePath="chartParamFestGeld.datasetColor1"
                    :hypotheken="false"
                    :festGeld="true"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <fieldset>
              <legend>Bank 2</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamFestGeld.bankname2"
                    :hypotheken="false"
                    :festGeld="true"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="false"
                    :festGeld="true"
                    storePath="chartParamFestGeld.datasetColor2"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <fieldset>
              <legend>Bank 3</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamFestGeld.bankname3"
                    :hypotheken="false"
                    :fest-geld="true"
                  />
                </v-col>
                <v-col class="pt-0 pb-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="false"
                    :fest-geld="true"
                    storePath="chartParamFestGeld.datasetColor3"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            v-if="isShowAdditionalBanks.bank4"
          >
            <fieldset>
              <legend>Bank 4</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamFestGeld.bankname4"
                    :hypotheken="false"
                    :festGeld="true"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="false"
                    :festGeld="true"
                    storePath="chartParamFestGeld.datasetColor4"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            v-if="isShowAdditionalBanks.bank5"
          >
            <fieldset>
              <legend>Bank 5</legend>
              <v-row class="flex-column">
                <v-col class="pb-0">
                  <anbieter-selectbox
                    storePath="formParamFestGeld.bankname5"
                    :hypotheken="false"
                    :festGeld="true"
                  />
                </v-col>
                <v-col class="pb-0 pt-0">
                  <anbieter-chart-color-selectbox
                    :hypotheken="false"
                    :festGeld="true"
                    storePath="chartParamFestGeld.datasetColor5"
                  />
                </v-col>
              </v-row>
            </fieldset>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            @click="handleShowBankChanged"
          >
            <AddBankCard :isShowAdditionalBanks="isShowAdditionalBanks" />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <v-card-text class="pl-0 font"> Zeitraum </v-card-text>
            <v-select
              :color="color"
              item-color="selected"
              :attach="true"
              background-color="white"
              item-text="text"
              item-value="value"
              v-model.number="zeitraum"
              dense
              solo
              :items="[
                { text: 0, value: 0 },
                { text: 2, value: 2 },
                { text: 4, value: 4 },
                { text: 6, value: 6 },
                { text: 8, value: 8 },
                { text: 10, value: 10 },
              ]"
            >
              <template #selection="{ item }">
                <span>{{ item.text | zeitraumJahre }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span>{{ item.text | zeitraumJahre }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="isDemoUser && zeitraum === 0"
            class="d-flex align-center"
          >
            <Alert
              type="info"
              color="orange"
              message="Die Auswahl eines Zeitraums ist in der Demoversion nicht möglich.
              Sie sehen Daten für das Jahr 2020"
            />
          </v-col>
          <v-col
            v-if="zeitraum !== 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          ></v-col>
          <v-col
            v-if="zeitraum !== 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          ></v-col>
          <v-col
            v-if="zeitraum === 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <v-card-text class="font pl-0"> von </v-card-text>
            <date-picker
              storePath="formParamFestGeld.zeitraumVon"
              :max-date="new Date().toISOString().substr(0, 10)"
              min-date="2010-01-01"
              :hypotheken="false"
              :festGeld="true"
            />
          </v-col>
          <v-col
            v-if="zeitraum === 0 && !isDemoUser"
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
          >
            <v-card-text class="font pl-0"> bis </v-card-text>
            <date-picker
              storePath="formParamFestGeld.zeitraumBis"
              :max-date="new Date().toISOString().substr(0, 10)"
              min-date="2010-01-01"
              :hypotheken="false"
              :festGeld="true"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            class="pt-0 chips-container"
          >
            <v-card-text class="font pl-0"> Anlagebetrag </v-card-text>
            <v-chip-group column v-model.number="anlagebetrag">
              <v-chip
                active-class="active-tag"
                v-for="(tag, index) in anlagebetragTags"
                :key="index"
                :value="tag.value"
              >
                {{ tag.text | anlagebetrag(0) }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            class="pt-0"
          >
            <v-card-text class="font pl-0"> Angebote für </v-card-text>
            <v-select
              :color="color"
              item-color="selected"
              :attach="true"
              background-color="white"
              v-model.number="kundenkreis"
              item-text="text"
              item-value="value"
              solo
              dense
              :items="[
                { text: 0, value: 0 },
                { text: 1, value: 1 },
                { text: 2, value: 2 },
              ]"
            >
              <template #selection="{ item }">
                <span>{{ item.text | kundenkreis }}</span>
              </template>
              <template v-slot:item="{ item }"
                ><span>{{ item.text | kundenkreis }}</span></template
              >
            </v-select>
          </v-col>
          <v-col
            :sm="dynamicCol.sm"
            :md="dynamicCol.md"
            :lg="dynamicCol.lg"
            :xl="dynamicCol.xl"
            class="pt-0"
          >
            <v-card-text class="font pl-0"> Anlagedauer </v-card-text>
            <v-select
              :color="color"
              item-color="selected"
              :attach="true"
              background-color="white"
              v-model.number="anlagedauer"
              item-text="text"
              item-value="value"
              solo
              dense
              :items="[
                { text: '1 Monat', value: 1 },
                { text: '3 Monate', value: 3 },
                { text: '6 Monate', value: 6 },
                { text: '9 Monate', value: 9 },
                { text: '12 Monate', value: 12 },
                { text: '18 Monate', value: 18 },
                { text: '2 Jahre', value: 24 },
                { text: '3 Jahre', value: 36 },
                { text: '4 Jahre', value: 48 },
                { text: '5 Jahre', value: 60 },
                { text: '6 Jahre', value: 72 },
                { text: '7 Jahre', value: 84 },
                { text: '8 Jahre', value: 96 },
                { text: '9 Jahre', value: 108 },
                { text: '10 Jahre', value: 120 },
              ]"
            >
              <template #selection="{ item }">
                <span>{{ item.text }}</span>
              </template>
              <template v-slot:item="{ item }"
                ><span>{{ item.text }}</span></template
              >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-checkbox
              :color="color"
              v-model="vermittlerangebote.selected"
              @click="handleChangeSelected(vermittlerangebote)"
              label="Vermittlerangebote in der Chart-Auswahl bei Maximum, Mittelwert und Minimum berücksichtigen"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col></v-col>
          <v-col>
            <v-btn
              class="anzeigen-btn action-btn"
              color="primary"
              @click="submitForm"
              >auswertung anzeigen</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              class="pl-0 action-btn"
              :color="color"
              plain
              @click="resetForm"
              >zurücksetzen</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapMutations } from 'vuex';

import AnbieterSelectbox from '../../common/BanksSelectBox/AnbieterSelectbox';
import DatePicker from '../../common/DatePicker/DatePicker';
import AnbieterChartColorSelectbox from '../../common/ChartColorSelectBox/AnbieterChartColorSelectbox';
import Loader from '../../common/Loader/Loader';
import AddBankCard from '../../common/AddBankCard/AddBankCard';
import Alert from '../../common/Alert/Alert';

import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
} from '../../../../../src/utils/constants';

export default {
  name: 'Form',
  components: {
    AnbieterSelectbox,
    DatePicker,
    AnbieterChartColorSelectbox,
    Loader,
    AddBankCard,
    Alert,
  },
  props: {
    loadPdf: Function,
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/isAuthorized',
      formParam: 'param/getFormParamFestgeld',
      user: 'auth/getUser',
    }),
    ...mapFields('param', ['formParamFestGeld.zeitraum']),
    ...mapFields('param', ['formParamFestGeld.zeitraumVon']),
    ...mapFields('param', ['formParamFestGeld.zeitraumBis']),
    ...mapFields('param', ['formParamFestGeld.kundenkreis']),
    ...mapFields('param', ['formParamFestGeld.vermittlerangebote']),
    ...mapFields('param', ['formParamFestGeld.anlagebetrag']),
    ...mapFields('param', ['formParamFestGeld.anlagedauer']),
    isLoading() {
      return this.loading;
    },
    dynamicCol() {
      if (
        Object.keys(this.isShowAdditionalBanks).some(
          (key) => this.isShowAdditionalBanks[key]
        )
      ) {
        return { xs: '12', sm: '12', md: '6', lg: '4', xl: '2' };
      }
      return { xs: '12', sm: '12', md: '6', lg: '3', xl: '2' };
    },
    isDemoUser() {
      return this.user?.isDemoUser || false;
    },
  },
  data() {
    return {
      isShowAdditionalBanks: {
        bank4: false,
        bank5: false,
      },
      anlagebetragTags: [
        { text: 10000, value: 10000 },
        { text: 25000, value: 25000 },
        { text: 50000, value: 50000 },
      ],
      color: BASE_BLACK_COLOR,
      baseColor: BASE_COLOR,
      loading: false,
    };
  },
  methods: {
    ...mapMutations({
      saveParamFestGeld: 'param/saveParamFestGeld',
      updateVermittlerangebote: 'param/updateVermittlerangebote',
      updateCopyForm: 'param/updateCopyForm',
    }),
    handleShowBankChanged() {
      if (this.isShowAdditionalBanks.bank4 === false) {
        this.isShowAdditionalBanks = { bank4: true, bank5: false };
      } else {
        this.isShowAdditionalBanks = { bank4: true, bank5: true };
      }
    },
    exportPdf() {
      this.loadPdf();
    },
    printForm() {
      window.print();
    },
    handleChangeSelected(val) {
      this.updateVermittlerangebote({
        selected: val.selected,
        value: val.value === 0 ? 1 : 0,
        field: 'formParamFestGeld',
      });
    },
    async submitForm() {
      this.loading = true;
      await this.loadChartDataFestGeld();
      this.loading = false;
    },
    resetForm() {
      this.$store.dispatch('resetFestGeld');
    },
    async loadChartDataFestGeld() {
      try {
        await this.$store.dispatch(
          'result/loadChartDataFestGeld',
          this.$store.state.param.formParamFestGeld
        );
        setTimeout(() => {
          this.updateCopyForm({
            copyFormParamFestGeld: this.$store.state.param.formParamFestGeld,
          });
        }, 200);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async loadAnbieter() {
      try {
        await this.$store.dispatch('anbieter/loadAnbieterFestGeld', 1);
        await this.$store.dispatch('anbieter/loadAnbieterFestGeld', 2);
        await this.$store.dispatch('anbieter/loadAnbieterFestGeld', 4);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async isAuthorized(val) {
      if (val) {
        this.loading = true;
        await this.$store.dispatch('param/loadParamFestGeld');
        await this.$store.dispatch(
          'result/loadChartDataFestGeldFromLocalStorage'
        );
        await this.loadAnbieter();
        this.loading = false;
      }
    },
  },
  async created() {
    if (this.isAuthorized) {
      this.loading = true;
      await this.$store.dispatch('param/loadParamFestGeld');
      await this.$store.dispatch(
        'result/loadChartDataFestGeldFromLocalStorage'
      );
      await this.loadAnbieter();
      this.loading = false;
    }
  },
  updated() {
    this.saveParamFestGeld();
  },
};
</script>

<style lang="scss" scoped>
.card-text {
  font-weight: bold;
  padding-left: 0;
  white-space: pre;
}
.dropdown.is-fullwidth {
  width: 100%;
}
.dropdown-trigger {
  width: 100%;
}
.dropdown .button {
  display: flex;
  width: 100%;
  justify-content: left;
}
.dropdown .button .button-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown .button .icon.is-right {
  margin-left: auto !important;
}
.v-card__actions > button {
  padding: 0 !important;
  min-width: 40px !important;
  margin-left: 15px;
}
.v-btn__content {
  & > i {
    padding: 5px;
    border-radius: 5px;
    background-color: $text;
  }
}
.v-text-field__details {
  display: none !important;
  position: absolute;
}
</style>
